import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { IngredientService } from '../services/ingredient.service';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';

const TOKEN_KEY = 'Authorization';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authenticationState = new BehaviorSubject(false);

  constructor(
    private storage: Storage,
    private plt: Platform,
    private ingredientService: IngredientService,
    private api: ApiService,
    private router: Router
  ) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.api.init().then(() => {
          this.api.getLoggedInUserInfo().then(response => {
            this.storage.set('user', response.data);
          });
          this.authenticationState.next(true);
        });
      }
    });
  }

  login(token) {
    return this.storage.set(TOKEN_KEY, `Bearer ${token}`).then(() => {
      this.api.init().then(() => {
        this.api.getLoggedInUserInfo().then((res) => {
          this.storage.set('user', res.data).then(() => {
            this.authenticationState.next(true);
          });
        });
      });
    });
  }

  logout() {
    // this.storage.clear();
    return this.storage.remove(TOKEN_KEY).then(() => {
      this.authenticationState.next(false);
      this.router.navigate(['login']);
    });
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

}
