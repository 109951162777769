/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ApiService } from './services/api.service';
import { AppService } from './services/app.service';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { UiService } from './services/ui.service';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  bucket = null;
  posts = [];

  forceLoad = false;

  widthClass;

  constructor(
    private storage: Storage,
    private platform: Platform,
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private menuCtrl: MenuController,
    private api: ApiService,
    private appService: AppService,
    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    private barcodeScanner: BarcodeScanner,
    private ui: UiService,
    private mobileAccessibility: MobileAccessibility,
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    await this.storage.create();
    this.widthClass = this.appService.widthClass;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (!this.authenticationService.isAuthenticated()) {
        this.router.navigate(['login']);
      }

      this.authenticationService.authenticationState.subscribe(state => {
        if (state) {
          this.storage.get('user').then((user) => {
            if (user) {
              this.userService.init(user).then(() => {
                let url = this.router.url;
                if (url === '/') {
                  url = 'dashboard';
                }

                const route = user?.meta_data.first_time_user === true ? ['onboarding'] : [url];
                this.router.navigate(route);
              });

              this.appService.loadShoppingListToStore();
              this.loadLatestCacheVersion().then(() => {
                // this.loadIngredientsToStore();
                this.loadRecipesToStore();
                this.loadExercisesToStore();
                this.loadExercisePlansToStore();
              });
            }
          });
        }
      });
    });
  }

  loadLatestCacheVersion() {
    return new Promise((resolve) => {
      this.storage.get('cache_version').then((res) => {
        this.api.getSetting('cache_version').then((cv) => {
          if (res !== cv.data) {
            this.forceLoad = true;
            this.storage.set('cache_version', cv.data);
          }
          resolve(true);
        });
      });
    });
  }

  // methods
  closeMenu() {
    this.menuCtrl.close();
  }

  loadIngredientsToStore() {
    this.api.getIndexedIngredients().then((response) => {
      this.storage.set('ingredients', response.data);
    });
  }

  loadRecipesToStore() {
    this.storage.get('recipes').then((res) => {
      if (!res || this.forceLoad) {
        this.api.getAllRecipes().then((response) => {
          this.storage.set('recipes', response.data);
        });

        this.api.getRecipeCategories().then((response) => {
          this.storage.set('recipe_categories', response.data);
        });

        this.api.getRecipesCategoriesIndex().then((response) => {
          this.storage.set('recipe_categories_index', response.data);
        });
      }
    });
  }

  loadExercisesToStore() {
    this.storage.get('exercise_sections').then((res) => {
      if (!res || this.forceLoad) {
        this.api.getGymExercisesSections().then((response) => {
          this.storage.set('exercise_sections', response.data);
        });

        this.api.getGymExercises().then((response) => {
          this.storage.set('all_exercises', response.data);
        });
      }
    });
  }

  loadExercisePlansToStore() {
    this.storage.get('exercise_plans').then((plans) => {
      if (!plans || this.forceLoad) {
        this.api.getExercisePlans().then((response) => {
          this.storage.set('exercise_plans', response.data);
        });
      }
    });
  }

  logout() {
    this.authenticationService.logout();
    this.closeMenu();
  }

  setupPush() {
    setTimeout(() => {
      // I recommend to put these into your environment.ts
      this.oneSignal.startInit('be60ccdd-52be-425b-8ee2-9ac8710fe408', '288283417321');
      this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

      this.oneSignal.postNotification({
        contents: {
          en: 'Hey Jason Welcome!'
        },
        headings: {
          en: 'Hello'
        }
      });

      // Notifcation was received in general
      this.oneSignal.handleNotificationReceived().subscribe(data => {
        const msg = data.payload.body;
        const title = data.payload.title;
        const additionalData = data.payload.additionalData;
        this.showAlert(title, msg, additionalData.task);

      });

      // Notification was really clicked/opened
      this.oneSignal.handleNotificationOpened().subscribe(data => {
        // Just a note that the data is a different place here!
        const additionalData = data.notification.payload.additionalData;

        this.showAlert('Notification opened', 'You already read this before', additionalData.task);
      });

      this.oneSignal.endInit();

      this.oneSignal.getIds().then(identity => {
        const user = this.userService.user;
        if (user.email) {
          const onesignal_id = user.onesignalId ? user.onesignalId.split(',') : [];

          if (!onesignal_id.some(e => e === identity.userId)) {
            onesignal_id.push(identity.userId);

            this.userService.user.onesignalId = onesignal_id.toString();
            this.userService.update(true);
            this.alertCtrl.create({
              message: 'Done'
            });
          }
        }
      }).catch(error => {
        alert(error);
      });
    }, 5000);
  }

  async showAlert(title, msg, task) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `Action: ${task}`,
          handler: () => {
            // E.g: Navigate to a specific screen
          }
        }
      ]
    });
    alert.present();
  }

  openBarcodeScanner() {
    this.barcodeScanner.scan().then(barcodeData => {
      this.api.searchIngredientByBarcode(barcodeData.text).then((res) => {
        const create = (res.data === null);
        this.handleIngredient(create ? { barcode: barcodeData.text } : res.data, create);
        this.closeMenu();
      });
    }).catch(err => {
      console.log('Error', err);
    });
  }

  handleIngredient(ingredient, create: boolean) {
    if (!create) {
      this.router.navigate(['/track-ingredient', 'none', ingredient.id]);
    } else {
      this.ui.showConfirm(
        'Unable to find ingredient',
        'We couldn\'t find this ingredient. Would you like to create it manually?',
        [
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Yes',
            cssClass: 'primary',
            handler: () => {
              this.router.navigate(['/create-ingredient'], {
                state: {
                  ingredient,
                  meal: 'none',
                }
              });
            },
          },
        ]);
    }
  }

  navigateToShopping() {
    this.userService.checkMembership('Shopping features are only available for premium users').then((isMember) => {
      if (isMember) {
        this.router.navigate(['shopping-list']);
      }
      this.closeMenu();
    });
  }

  get showMenu() {
    return true; //this.router.url !== '/login' && this.router.url !== '/register' && this.router.url !== '/onboarding';
  }
}
