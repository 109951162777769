/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '', loadChildren: () => import('./splashscreen/splashscreen.module').then(m => m.SplashscreenPageModule) },
      { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
      { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule) },
      { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
      { path: 'onboarding', loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingPageModule) },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule) },
      { path: 'track-weight', loadChildren: () => import('./track-weight/track-weight.module').then(m => m.TrackWeightPageModule) },
      { path: 'track-body-measurements', loadChildren: () => import('./track-body-measurements/track-body-measurements.module').then(m => m.TrackBodyMeasurementsPageModule) },
      { path: 'my-progress', loadChildren: () => import('./my-progress/my-progress.module').then(m => m.MyProgressPageModule) },
      { path: 'food-diary', loadChildren: () => import('./food-diary/food-diary.module').then(m => m.FoodDiaryPageModule) },
      { path: 'start-mealplan', loadChildren: () => import('./start-mealplan/start-mealplan.module').then(m => m.StartMealplanPageModule) },
      { path: 'mealplan-details/:id', loadChildren: () => import('./mealplan-details/mealplan-details.module').then(m => m.MealplanDetailsPageModule) },
      { path: 'search-foods/:meal', loadChildren: () => import('./search-foods/search-foods.module').then(m => m.SearchFoodsPageModule) },
      { path: 'track-ingredient/:meal/:ingredientId', loadChildren: () => import('./track-ingredient/track-ingredient.module').then(m => m.TrackIngredientPageModule) },
      { path: 'track-recipe/:meal/:recipeId', loadChildren: () => import('./track-recipe/track-recipe.module').then(m => m.TrackRecipePageModule) },
      { path: 'keto', loadChildren: () => import('./keto/keto.module').then(m => m.KetoPageModule) },
      { path: 'metabolic', loadChildren: () => import('./metabolic/metabolic.module').then(m => m.MetabolicPageModule) },
      { path: 'recipe-details/:recipeId', loadChildren: () => import('./recipe-details/recipe-details.module').then(m => m.RecipeDetailsPageModule) },
      { path: 'create-ingredient', loadChildren: () => import('./create-ingredient/create-ingredient.module').then(m => m.CreateIngredientPageModule) },
      { path: 'shopping-list', loadChildren: () => import('./shopping-list/shopping-list.module').then(m => m.ShoppingListPageModule) },
      { path: 'exercise-diary', loadChildren: () => import('./exercise-diary/exercise-diary.module').then(m => m.ExerciseDiaryPageModule) },
      { path: 'track-exercise-activity', loadChildren: () => import('./track-exercise-activity/track-exercise-activity.module').then(m => m.TrackExerciseActivityPageModule) },
      { path: 'track-exercise-gym', loadChildren: () => import('./track-exercise-gym/track-exercise-gym.module').then(m => m.TrackExerciseGymPageModule) },
      { path: 'track-exercise-gym/:id', loadChildren: () => import('./track-exercise-gym-single/track-exercise-gym-single.module').then(m => m.TrackExerciseGymSinglePageModule) },
      { path: 'exercise-plans', loadChildren: () => import('./exercise-plans/exercise-plans.module').then(m => m.ExercisePlansPageModule) },
      { path: 'exercise-plans/:id', loadChildren: () => import('./exercise-plan/exercise-plan.module').then(m => m.ExercisePlanPageModule) },
      { path: 'my-profile', loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule) },
      { path: 'test-map', loadChildren: () => import('./test-map/test-map.module').then(m => m.TestMapPageModule) },
      { path: 'edit-food-tracking/:id', loadChildren: () => import('./edit-food-tracking/edit-food-tracking.module').then(m => m.EditFoodTrackingPageModule) },
      { path: 'edit-recipe-tracking/:id', loadChildren: () => import('./edit-recipe-tracking/edit-recipe-tracking.module').then(m => m.EditRecipeTrackingPageModule) },
      { path: 'privacy-policy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule) },
      { path: 'terms-and-conditions', loadChildren: () => import('./terms-and-conditions/terms-and-conditions-routing.module').then(m => m.TermsAndConditionsPageRoutingModule) },
      { path: 'upgrade-account', loadChildren: () => import('./upgrade-account/upgrade-account.module').then(m => m.UpgradeAccountPageModule) },
    ])
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
