import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController, ModalController } from '@ionic/angular';
import { SelectComponent } from '../components/modals/select/select.component';
import { UpgradeComponent } from '../components/modals/upgrade/upgrade.component';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  loadingModal;

  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
  ) { }

  async showLoading(message) {
    this.loadingModal = await this.loadingCtrl.create({
      message
    });
    await this.loadingModal.present();
  }

  hideLoading() {
    if (this.loadingModal) {
      this.loadingModal.dismiss();
    }
  }

  async showToast(message, color = 'danger', buttons = [], duration = 0) {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      color,
      position: 'bottom',
      buttons
    });
    toast.present();
  }

  async showConfirm(header, message, buttons) {
    const alert = await this.alertCtrl.create({ header, message, buttons });
    await alert.present();
  }

  async showPrompt(header, buttons, inputs) {
    const alert = await this.alertCtrl.create({ header, inputs, buttons });

    await alert.present();
  }

  async showSimpleSelect(options, allowSearching = true, searchPlaceholder = null) {
    const modal = await this.modalCtrl.create({
      component: SelectComponent,
      componentProps: {
        options,
        allowSearching,
        searchPlaceholder,
      }
    });

    modal.present();

    return modal.onDidDismiss().then((data) => data.data);
  }

  async showUpgradeModal() {
    const modal = await this.modalCtrl.create({
      component: UpgradeComponent,
    });

    modal.present();
  }
}
