import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeComponent implements OnInit {
  features = [
    { title: 'Log Food', free: true, },
    // eslint-disable-next-line max-len
    { title: 'Barcode Food Scan', description: 'Includes ability to scan most Maltese Products.', free: false, premium: true, terms: '3 scans per day' },
    { title: 'Track Water', free: true, },
    { title: 'Track Weight', free: true, },
    { title: 'Track Measurements', free: true, },
    { title: 'Picture Progress', free: true, },
    { title: 'Log Exercises', free: true, },
    { title: 'Food Search', free: true, },
    { title: 'Shopping List', free: true, },
    { title: 'Advanced Food Search', free: false, },
    { title: 'Detox Diet', free: false, },
    { title: 'Low-Carb Diet', free: false, },
    { title: 'Healthy-Heart Diet', free: false, },
    { title: 'Gluten-Free Diet', free: false, },
    { title: 'Vegetarian Diet', free: false, },
    { title: 'Paleo Diet', free: false, },
    { title: 'Muscle-Gain Diet', free: false, },
    { title: 'Keto Calculator', free: false, },
    { title: 'Metabolic Calculator', free: false, },
    { title: 'Track Gym Exercises', free: false, },
    { title: 'Six Personal Training Programs', free: false, },
    { title: 'GPS Training', free: false, },
  ];

  expandSelector = false;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    const mainc = document.getElementById('mainContainer');
    mainc.onscroll = (ev) => {
      if (mainc.scrollTop > 1300) {
        this.expandSelector = true;
      }
  };
  }

  returnValue(value) {
    this.modalCtrl.dismiss(value);
  }

  closeSelector() {
    this.modalCtrl.dismiss();
  }

  upgradeMonths(months) {
    console.log(`Upgrading for ${months} months`);
  }
}
