import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() options;
  @Input() allowSearching;
  @Input() searchPlaceholder;

  filter = '';

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  returnValue(value) {
    this.modalCtrl.dismiss(value);
  }

  closeSelector() {
    this.modalCtrl.dismiss();
  }

  get filteredOptions() {
    return this.filter.length > 0 ? this.options.filter(e => e.text.toLowerCase().includes(this.filter.toLowerCase())) : this.options;
  }

}
