/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable radix */
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Storage } from '@ionic/storage';
import { UserUpdatePayload, WaterIntakePayload } from 'src/app/models/payloads';
import { UiService } from '../services/ui.service';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: User;
  constructor(
    private storage: Storage,
    private ui: UiService,
    private api: ApiService,
    private router: Router,
  ) {
    this.user = new User();
  }

  get() {
    return this.user;
  }

  set(user: User) {
    Object.keys(user).forEach(field => {
      this.user[field] = user[field];
    });
  }

  init(user: any) {
    return new Promise((resolve) => {
      this.storage.set('user', user);

      const serviceUser: User = {
        name: user.name,
        email: user.email,
        activityLevel: parseInt(user.meta_data.activity_level),
        bodyFatDistribution: parseInt(user.meta_data.body_fat_distribution),
        bodyType: user.meta_data.body_type,
        dateOfBirth: user.meta_data.date_of_birth,
        dietGoal: user.meta_data.diet_goal,
        exerciseLevel: user.meta_data.exercise_level,
        gender: user.meta_data.gender,
        goalWeight: user.meta_data.goal_weight,
        preferredUnits: user.meta_data.preferred_units,
        height: user.meta_data.height,
        vipUser: user.vip_user,
        currentMealplan: user.meta_data.diet_name,
        onesignalId: user.meta_data.onesignal_id,
      };

      this.set(serviceUser);
      resolve(true);
    });
  }

  update(silent = false) {
    const payload: UserUpdatePayload = {
      profile: {
        activity_level: this.user.activityLevel,
        body_fat_distribution: this.user.bodyFatDistribution,
        body_type: this.user.bodyType,
        date_of_birth: this.user.dateOfBirth,
        diet_goal: this.user.dietGoal,
        exercise_level: this.user.exerciseLevel,
        gender: this.user.gender,
        goal_weight: this.user.goalWeight,
        height: this.user.height,
        is_lactating: this.user.isLactating,
        is_pregnant: this.user.isPregnant,
        name: this.user.name,
        preferred_units: this.user.preferredUnits,
        onesignal_id: this.user.onesignalId
      }
    };

    if (!silent) {
      this.ui.showLoading('Updating profile');
    }

    this.api.updateProfileDetails(payload).subscribe(data => {
      this.api.getLoggedInUserInfo().then(user => {
        this.storage.set('user', user);
        this.ui.hideLoading();
        this.ui.showToast('Profile updated', 'success', null, 2000);
      });
    });
  }

  getMobileDashboard(date: string) {
    return new Promise((resolve, reject) => {
      this.api.getMobileDashboard(date).subscribe(
        res => {
          resolve(res);
        }, (err) => {
          reject('Could not get Dashboard Data' + err);
        });
    });
  }

  getDashboardWeight(date: string) {
    return new Promise((resolve, reject) => {
      this.api.getDashboardWeight(date).subscribe(
        res => {
          resolve(res);
        }, (err) => {
          reject('Could not get Weight Data' + err);
        });
    });
  }

  getDashboardWater(date: string) {
    return new Promise((resolve, reject) => {
      this.api.getDashboardWater(date).subscribe(
        res => {
          resolve(res);
        }, (err) => {
          reject('Could not get Weight Data' + err);
        });
    });
  }

  getDashboardNutrients(date: string) {
    return new Promise((resolve, reject) => {
      this.api.getDashboardNutrients(date).subscribe(
        res => {
          resolve(res);
        }, (err) => {
          reject('Could not get Weight Data' + err);
        });
    });
  }

  getTodaysFood(date: string) {
    return new Promise((resolve, reject) => {
      this.ui.showLoading('Loading Food Diary...');
      this.api.getFoodforDate(date).subscribe(
        res => {
          this.ui.hideLoading();
          resolve(res);
        }, (err) => {
          this.ui.hideLoading();
          reject('Could not get Food Diary Data' + err);
        });
    });
  }

  getTodaysExercise(date: string) {
    return new Promise((resolve, reject) => {
      this.api.getExerciseforDate(date).subscribe(
        res => {
          this.ui.hideLoading();
          resolve(res);
        }, (err) => {
          this.ui.hideLoading();
          reject('Could not get Exercise Data here is the error: ' + err);
        });
    });
  }

  getTodaysGPSExercise(date: string) {
    return new Promise((resolve, reject) => {
      this.api.getGPSTrainingTracking(date).then(
        res => {
          this.ui.hideLoading();
          resolve(res);
        }, (err) => {
          this.ui.hideLoading();
          reject('Could not get Exercise Data here is the error: ' + err);
        });
    });
  }

  deleteFoodTrackingRecord(id: number) {
    return new Promise((resolve, reject) => {
      this.ui.showLoading('Removing Food Tracking...');
      this.api.deleteFoodTracking(id).then(
        res => {
          this.ui.hideLoading();
          resolve(res);
        }, (err) => {
          this.ui.hideLoading();
          reject('Could not get Dashboard Data' + err);
        });
    });
  }

  trackWater(ml: number, date: Date) {
    return new Promise((resolve) => {
      const payload: WaterIntakePayload = {
        date,
        number_of_milliliters: ml,
      };
      this.api.trackWater(payload).subscribe(data => {
        resolve(data);
      });
    });
  }

  checkMembership(confirmMessage) {
    return new Promise((resolve) => {
      let isMember = false;
      this.api.checkMembership().then(res => {
        isMember = res.data;
        if (!isMember) {
          this.ui.showConfirm('Upgrade Your Account', confirmMessage, [{
            text: 'Keep Basic Account',
            cssClass: 'not-important',
            handler: () => {
              resolve(false);
            }
          }, {
            text: 'Upgrade',
            cssClass: 'important',
            handler: () => {
              this.storage.get('user').then((userDetails) => {
                this.router.navigate(['upgrade-account']);
              });
            }
          }]);
        } else {
          resolve(true);
        }
      });
    });
  }
}
