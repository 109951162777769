import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectComponent } from './components/modals/select/select.component';
import { UpgradeComponent } from './components/modals/upgrade/upgrade.component';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { FormsModule } from '@angular/forms';

import { Insomnia } from '@ionic-native/insomnia/ngx';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';

@NgModule({
  declarations: [
    AppComponent,
    SelectComponent,
    UpgradeComponent,
  ],
  entryComponents: [],
  imports: [
    FormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    NgApexchartsModule,
    NgCircleProgressModule.forRoot({
      backgroundOpacity: 0,
      radius: 60,
      space: -4,
      outerStrokeWidth: 10,
      outerStrokeColor: '#ffffff',
      outerStrokeGradientStopColor: '#ae95ff',
      outerStrokeLinecap: 'square',
      innerStrokeColor: '#ccbdff',
      innerStrokeWidth: 10,
      animateTitle: true,
      animationDuration: 1000,
      showTitle: false,
      showUnits: false,
      showSubtitle: false,
      showBackground: false,
      clockwise: true,
      startFromZero: false,
      lazy: false
    }),
  ],
  providers: [{
    provide: RouteReuseStrategy,
    useClass: IonicRouteStrategy
  },
  BarcodeScanner,
  Insomnia,
  Geolocation,
  NativeGeocoder,
  OneSignal,
  MobileAccessibility,
],
  bootstrap: [AppComponent],
})
export class AppModule {
}
