import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  date;

  foodChanged = true;
  weightChanged = true;
  dashboardWeightData = {
    startingWeight: 0,
    currentWeight: 0,
    goalWeight: 0,
    bmiCategoryIndex: 0,
    bmiCategory: '',
    goalProgressPercentage: 0,
  };

  waterIntakeChanged = true;
  dashboardWaterData = {
    intake: 0,
    goal: 0,
    percentage: 0,
  };

  widthClass = 'small';

  constructor(
    private api: ApiService,
    private storage: Storage,
  ) {
    this.date = moment().format('YYYY-MM-DD');
  }

  loadShoppingListToStore() {
    let shoppingList;
    this.api.getShoppingList().then((res) => {
      shoppingList = res.data.sort((a,b) => a.is_done - b.is_done);
      shoppingList.forEach(item => {
        item.is_done = item.is_done === '1';
      });

      this.storage.set('shopping_list', shoppingList);
    });
  }
}
