/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AuthLoginEmailPasswordPayload,
  AuthRegisterAccountPayload,
  IngredientsCreateIngredientPayload,
  PhotoUploadPayload,
} from '../models/payloads';
import { ApiResponse, ApiResponseWithArray } from '../models/api-response';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiURL = 'https://api.nutrivitas.com/api/v1';
  // apiURL = 'http://localhost:8000/api/v1';
  httpOptions = {};

  constructor(
    private http: HttpClient,
    private storage: Storage,
  ) {

  }

  public init() {
    return new Promise((resolve) => {
      this.storage.get('Authorization').then(authKey => {
        this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: authKey,
            'Access-Control-Allow-Origin': 'http://localhost:8100'
          })
        };
        resolve(true);
      });
    });
  }

  public loginEmailPassword(loginData: AuthLoginEmailPasswordPayload) {
    return this.http.post<ApiResponse>(`${this.apiURL}/login`, loginData).toPromise();
  }

  public registerAccount(registerData: AuthRegisterAccountPayload) {
    return this.http.post<ApiResponse>(`${this.apiURL}/register`, registerData).toPromise();
  }

  public forgotPassword(email: string) {
    return this.http.post<ApiResponse>(`${this.apiURL}/forgot-password/${email}`, null).toPromise();
  }

  public createIngredient(payload: IngredientsCreateIngredientPayload) {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/food/create`, payload, this.httpOptions).toPromise();
  }

  public searchIngredientByBarcode(barcode: string) {
    return this.http.get<ApiResponse>(`${this.apiURL}/search/ingredients/barcode/${barcode}`).toPromise();
  }

  public searchIngredientByIngredientName(search_term: string, pageNumber: number, include_barcoded: boolean) {
    return this.http.post<ApiResponse>(`${this.apiURL}/search-ingredient?page=${pageNumber}`, { search_term, include_barcoded }).toPromise();
  }

  public searchIngredientByIngredientNameInCategory(search_term: string, pageNumber: number, category: number, include_barcoded: boolean) {
    return this.http.post<ApiResponse>(`${this.apiURL}/search-category-ingredient?page=${pageNumber}`, { search_term, category, include_barcoded }).toPromise();
  }

  public recordSearchQuery(searchQuery: string) {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/record-search-query`, { searchQuery }, this.httpOptions).toPromise();
  }

  public getAllIngredients() {
    return this.http.get<ApiResponse>(`${this.apiURL}/ingredients`).toPromise();
  }

  public getIngredientsById(id) {
    return this.http.get<ApiResponse>(`${this.apiURL}/ingredients/${id}`).toPromise();
  }

  public getIndexedIngredients() {
    return this.http.get<ApiResponse>(`${this.apiURL}/ingredients/index`).toPromise();
  }

  public getFoodLabels() {
    return this.http.get<ApiResponse>(`${this.apiURL}/foodlabels`).toPromise();
  }

  public getNutrients() {
    return this.http.get<ApiResponse>(`${this.apiURL}/nutrients`).toPromise();
  }

  public getLoggedInUserInfo() {
    return this.http.get(`${this.apiURL}/me`, this.httpOptions).toPromise() as any;
  }

  public uploadPhoto(photo: PhotoUploadPayload): Observable<any> {
    return this.http.post(`${this.apiURL}/me/photo`, photo, this.httpOptions);
  }

  public updateProfileDetails(payload): Observable<any> {
    return this.http.post(`${this.apiURL}/me/update`, payload, this.httpOptions);
  }

  public trackWater(payload): Observable<any> {
    return this.http.post(`${this.apiURL}/me/water/track`, payload, this.httpOptions);
  }

  public trackFood(payload) {
    return this.http.post(`${this.apiURL}/me/food/track`, payload, this.httpOptions).toPromise();
  }

  public updateFoodTracking(payload) {
    return this.http.post(`${this.apiURL}/me/food/update-tracking`, payload, this.httpOptions).toPromise();
  }

  public updateRecipeTracking(payload) {
    return this.http.post(`${this.apiURL}/me/food/update-recipe-tracking`, payload, this.httpOptions).toPromise();
  }

  public trackMealPlan(payload) {
    return this.http.post(`${this.apiURL}/me/meal-plan/track-alt`, payload, this.httpOptions).toPromise();
  }

  public trackRecipe(payload) {
    return this.http.post(`${this.apiURL}/me/food/track/recipe`, payload, this.httpOptions).toPromise();
  }

  public trackExercise(payload) {
    return this.http.post(`${this.apiURL}/me/exercise/track`, payload, this.httpOptions).toPromise();
  }

  public trackGPSTrainingTracking(payload) {
    return this.http.post(`${this.apiURL}/me/gps-tracking`, payload, this.httpOptions).toPromise();
  }

  public getGPSTrainingTracking(date) {
    return this.http.get(`${this.apiURL}/me/gps-tracking/${date}`, this.httpOptions).toPromise();
  }

  public addFoodToShoppingList(payload) {
    return this.http.post(`${this.apiURL}/me/shopping`, payload, this.httpOptions).toPromise();
  }

  public addDayToShoppingList(date) {
    return this.http.post(`${this.apiURL}/me/shopping/day/${date}`, null, this.httpOptions).toPromise();
  }

  public addRecipeToShoppingList(payload) {
    return this.http.post(`${this.apiURL}/me/shopping/recipe`, payload, this.httpOptions).toPromise();
  }

  public addFoodToFavourites(payload) {
    return this.http.post(`${this.apiURL}/me/favourites/ingredients/new`, payload, this.httpOptions).toPromise();
  }

  public addRecipeToFavourites(payload) {
    return this.http.post(`${this.apiURL}/me/favourites/recipes/new`, payload, this.httpOptions).toPromise();
  }

  public getFavouriteIngredients() {
    return this.http.get(`${this.apiURL}/me/favourites/ingredients`, this.httpOptions).toPromise();
  }

  public getFavouriteRecipes() {
    return this.http.get(`${this.apiURL}/me/favourites/recipes`, this.httpOptions).toPromise();
  }

  public trackWeight(payload) {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/progress/track`, payload, this.httpOptions).toPromise();
  }

  public trackMeasurements(payload) {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/progress/trackmeasurements`, payload, this.httpOptions).toPromise();
  }

  public deleteProgress(id) {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/progress/delete/${id}`, null, this.httpOptions).toPromise();
  }

  public deleteFoodTracking(id) {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/food/delete/${id}`, null, this.httpOptions).toPromise();
  }

  public getBraintreeToken(): Observable<any> {
    return this.http.get(`${this.apiURL}/me/payments/token`, this.httpOptions);
  }

  public getMobileDashboard(date: string): Observable<any> {
    return this.http.get(`${this.apiURL}/me/mobile-dashboard-v2/${date}`, this.httpOptions);
  }

  public getDashboardWeight(date: string): Observable<any> {
    return this.http.get(`${this.apiURL}/me/mobile-dashboard/${date}/weight`, this.httpOptions);
  }

  public getDashboardWater(date: string): Observable<any> {
    return this.http.get(`${this.apiURL}/me/mobile-dashboard/${date}/water`, this.httpOptions);
  }

  public getDashboardNutrients(date: string): Observable<any> {
    return this.http.get(`${this.apiURL}/me/mobile-dashboard/${date}/nutrients`, this.httpOptions);
  }

  public getFoodforDate(date: string): Observable<any> {
    return this.http.get(`${this.apiURL}/me/foodindex/${date}`, this.httpOptions);
  }

  public getExerciseforDate(date: string): Observable<any> {
    return this.http.get(`${this.apiURL}/me/exercise/${date}`, this.httpOptions);
  }

  public deleteExerciseTracking(id: number) {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/exercise/delete/${id}`, null, this.httpOptions).toPromise();
  }

  public deleteGPSExerciseTracking(id: number) {
    return this.http.delete<ApiResponse>(`${this.apiURL}/me/gps-tracking/${id}`, this.httpOptions).toPromise();
  }

  public getProgress(metric: string) {
    return this.http.get<ApiResponse>(`${this.apiURL}/me/progress/${metric}`, this.httpOptions).toPromise();
  }

  public getProgressChart(metric: string) {
    return this.http.get<ApiResponseWithArray>(`${this.apiURL}/me/progress-chart/${metric}`, this.httpOptions).toPromise();
  }

  public getShoppingList() {
    return this.http.get<ApiResponseWithArray>(`${this.apiURL}/me/shopping`, this.httpOptions).toPromise();
  }

  public removeShoppingItem(item) {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/shopping/delete/${item}`, null, this.httpOptions).toPromise();
  }

  public toggleShoppingItem(item) {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/shopping/toggle/${item}`, null, this.httpOptions).toPromise();
  }

  public sendShoppingList() {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/shopping/send`, null, this.httpOptions).toPromise();
  }

  public getAllRecipes() {
    return this.http.get<ApiResponse>(`${this.apiURL}/recipes`).toPromise();
  }

  public getLatestRecipes() {
    return this.http.get<ApiResponseWithArray>(`${this.apiURL}/latest-recipes`).toPromise();
  }

  public getRecipeCategories() {
    return this.http.get<ApiResponse>(`${this.apiURL}/recipe-categories`).toPromise();
  }

  public getRecipesCategoriesIndex() {
    return this.http.get<ApiResponse>(`${this.apiURL}/recipes/categories/index`).toPromise();
  }

  public getSingleRecipe(recipeId) {
    return this.http.get<ApiResponse>(`${this.apiURL}/recipes/${recipeId}`).toPromise();
  }

  public getAllMealPlans() {
    return this.http.get<ApiResponse>(`${this.apiURL}/mealplans`).toPromise();
  }

  public getExercises() {
    return this.http.get<ApiResponse>(`${this.apiURL}/exercises`).toPromise();
  }

  public getGymExercisesSections() {
    return this.http.get<ApiResponseWithArray>(`${this.apiURL}/exercises/gym`).toPromise();
  }

  public getGymExercises() {
    return this.http.get<ApiResponseWithArray>(`${this.apiURL}/gym-exercises`).toPromise();
  }

  public getExerciseMotions(id) {
    return this.http.get<ApiResponse>(`${this.apiURL}/exercises/${id}`).toPromise();
  }

  public getExercisePlans() {
    return this.http.get<ApiResponse>(`${this.apiURL}/me/exercise-plans`, this.httpOptions).toPromise();
  }

  public getExercisePlan(id) {
    return this.http.get<ApiResponse>(`${this.apiURL}/me/exercise-plans/${id}`, this.httpOptions).toPromise();
  }

  public trackPersonalTrainingExercise(tracking) {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/personal-training/track`, tracking, this.httpOptions).toPromise();
  }

  public getPersonalTrainingExerciseHistory(id) {
    return this.http.get<ApiResponse>(`${this.apiURL}/me/personal-training/history/${id}`, this.httpOptions).toPromise();
  }

  public upgradeMembership(months) {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/upgrade-membership`, months, this.httpOptions).toPromise();
  }

  public cancelMembership() {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/cancel-membership`, null, this.httpOptions).toPromise();
  }

  public cancelStripeMembership() {
    return this.http.post<ApiResponse>(`${this.apiURL}/me/cancel-stripe-membership`, null, this.httpOptions).toPromise();
  }

  public checkMembership() {
    return this.http.get<ApiResponse>(`${this.apiURL}/me/check-membership`, this.httpOptions).toPromise();
  }

  public getSetting(setting) {
    return this.http.get<ApiResponse>(`${this.apiURL}/setting/${setting}`, this.httpOptions).toPromise();
  }

  public searchRestaurantIngredientByName(search_term: string, pageNumber: number, manufacturer: string) {
    return this.http.post<ApiResponse>(`${this.apiURL}/search-manufacturer-ingredient?page=${pageNumber}`, { search_term, manufacturer }).toPromise();
  }
}
