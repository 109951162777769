export class User {
  name?: string;
  email?: string;
  activityLevel?: number;
  bodyFatDistribution?: number;
  bodyType?: string;
  dateOfBirth?: Date;
  dietGoal?: string;
  exerciseLevel?: any;
  gender?: string;
  goalWeight?: number;
  preferredUnits?: string;
  isPregnant?: boolean;
  isLactating?: boolean;
  weight?: number;
  height?: number;
  vipUser?: boolean;
  currentMealplan?: string;
  preferredUnitsOfMeasure?: string;
  onesignalId?: string;
}